const enumLayoutConfiguration = {
  PRIMARYCOLOR: "PRIMARY_COLOR",
  SECONDARYCOLOR: "SECONDARY_COLOR",
  LOGOIMG: "LOGO_IMG",
  BANNERIMG: "BANNER_IMG",
};
const enumEmailConfiguration = {
  SMTPSERVER: "SMTP",
  SMTPPORT: "SMTP_PORT",
  SMTPEMAIL: "SMTP_EMAIL",
  SMTPPASS: "SMTP_PASS",
  SMTPFROM: "SMTP_FROM",
  SMTPTLS: "SMTP_START_TLS",
};
const enumContentConfiguration = {
  TERMSCONDITIONS: "TERMS_CONDITION",
  VUEAPPTITLECLIENT: "APP_TITLE_CLIENT",
  VUEAPPTITLE: "APP_TITLE",
};
const enumAPIConfiguration = {
  PATHSERVICELECOMMODEL: "PATH_SERVICE_LECOM_MODEL",
  PATHSERVICELECOMAUTHENTICATION: "PATH_SERVICE_LECOM_AUTHENTICATION",
  PATHSERVICELECOMOPENPROCESS: "PATH_SERVICE_LECOM_OPEN_PROCESS",
  PATHSERVICELECOMPROCESSINSTANCES: "PATH_SERVICE_LECOM_PROCESS_INSTANCES",
  PATHSERVICELECOMFORMAPP: "PATH_SERVICE_LECOM_FORM_APP",
  SERVICELECOMAUTHENTICATIONADMINLOGIN: "SERVICE_LECOM_AUTHENTICATION_ADMIN_LOGIN",
  SERVICELECOMAUTHENTICATIONADMINPASSWORD: "SERVICE_LECOM_AUTHENTICATION_ADMIN_PASSWORD",
  SERVICELECOMUSERPUBLICLOGIN: "SERVICE_LECOM_USER_PUBLIC_LOGIN",
  SERVICELECOMUSERPUBLICPASS: "SERVICE_LECOM_USER_PUBLIC_PASS",
  SERVICELECOMUSERPUBLICID: "SERVICE_LECOM_USER_PUBLIC_ID",
  URLSERVICELECOMBPM: "URL_SERVICE_LECOM_BPM",
};
const enumParameterConfiguration = {
  AUTHENTICATIONTYPE: "AUTHENTICATION_TYPE",
  LOGINPARAMETERURL: "LOGIN_PARAMETER_URL",
  LOGINPARAMETERAPIURL: "LOGIN_PARAMETER_API_URL",
  LOGINPARAMETERAPIUSER: "LOGIN_PARAMETER_API_USER",
  LOGINPARAMETERAPIPASSWORD: "LOGIN_PARAMETER_API_PASSWORD",
  OAUTHPKCEURL: "OAUTHPKCE_URL",
  OAUTHPKCEAUTHORIZEURL: "OAUTHPKCE_AUTHORIZE_URL",
  OAUTHPKCETOKENURL: "OAUTHPKCE_TOKEN_URL",
  OAUTHPKCEUSERINFOURL: "OAUTHPKCE_USER_INFO_URL",
  OAUTHPKCELOGINURL: "OAUTHPKCE_LOGIN_URL",
  OAUTHPKCELOGOUTURL: "OAUTHPKCE_LOGOUT_URL",
  OAUTHPKCEPOSTLOGOUTREDIRECTURL: "OAUTHPKCE_POST_LOGOUT_REDIRECT_URL",
  OAUTHPKCECLIENTID: "OAUTHPKCE_CLIENT_ID",
  OAUTHPKCECLIENTSECRET: "OAUTHPKCE_CLIENT_SECRET",
  OAUTHPKCEGRANTTYPE: "OAUTHPKCE_GRANT_TYPE",
  OAUTHPKCESCOPE: "OAUTHPKCE_SCOPE",
  OAUTHPKCEREDIRECTURI: "OAUTHPKCE_REDIRECT_URI",
  OAUTHPKCELOGINBUTTONENABLED: "OAUTHPKCE_LOGIN_BUTTON_ENABLED",
  OAUTHPKCELOGINBUTTONTEXT: "OAUTHPKCE_LOGIN_BUTTON_TEXT",
  OAUTHPKCEIDFIELD: "OAUTHPKCE_ID_FIELD",
  URLAMBIENTEFRONT: "URL_AMBIENTE_FRONT",
  APITOKENUSER: "API_TOKEN_USER",
  APITOKENPASSWORD: "API_TOKEN_PASSWORD",
  AUTHLAYOUTDIRECTION: "AUTH_LAYOUT_DIRECTION",
  FAILEDLOGINCOUNT: "FAILED_LOGIN_COUNT",
  APPTITLECLIENT: "APP_TITLE_CLIENT",
  APPTITLE: "APP_TITLE",
  GOVBROAUTHPKCEURL: "GOVBR_OAUTHPKCE_URL",
  GOVBROAUTHPKCEAUTHORIZEURL: "GOVBR_OAUTHPKCE_AUTHORIZE_URL",
  GOVBROAUTHPKCETOKENURL: "GOVBR_OAUTHPKCE_TOKEN_URL",
  GOVBROAUTHPKCEUSERINFOURL: "GOVBR_OAUTHPKCE_USER_INFO_URL",
  GOVBROAUTHPKCELOGINURL: "GOVBR_OAUTHPKCE_LOGIN_URL",
  GOVBROAUTHPKCELOGOUTURL: "GOVBR_OAUTHPKCE_LOGOUT_URL",
  GOVBROAUTHPKCEPOSTLOGOUTREDIRECTURL: "GOVBR_OAUTHPKCE_POST_LOGOUT_REDIRECT_URL",
  GOVBROAUTHPKCECLIENTID: "GOVBR_OAUTHPKCE_CLIENT_ID",
  GOVBROAUTHPKCECLIENTSECRET: "GOVBR_OAUTHPKCE_CLIENT_SECRET",
  GOVBROAUTHPKCEGRANTTYPE: "GOVBR_OAUTHPKCE_GRANT_TYPE",
  GOVBROAUTHPKCESCOPE: "GOVBR_OAUTHPKCE_SCOPE",
  GOVBROAUTHPKCEREDIRECTURI: "GOVBR_OAUTHPKCE_REDIRECT_URI",
  GOVBROAUTHPKCELOGINBUTTONENABLED: "GOVBR_OAUTHPKCE_LOGIN_BUTTON_ENABLED",
  GOVBROAUTHPKCELOGINBUTTONTEXT: "GOVBR_OAUTHPKCE_LOGIN_BUTTON_TEXT",
  GOVBROAUTHPKCEIDFIELD: "GOVBR_OAUTHPKCE_ID_FIELD",
  GOVBRSERVICELEVELSURL: "GOVBR_SERVICE_LEVELS_URL",
  GOVBRSERVICECATEGORIESURL: "GOVBR_SERVICE_CATEGORIES_URL",
  GOVBRSERVICERELIABILITIESURL: "GOVBR_SERVICE_RELIABILITIES_URL",
  GOVBRSERVICECOMPANIESURL: "GOVBR_SERVICE_COMPANIES_URL",
  GOVBRSERVICECOMPANYDETAILURL: "GOVBR_SERVICE_COMPANY_DETAIL_URL",
};
export { enumLayoutConfiguration, enumEmailConfiguration, enumContentConfiguration, enumAPIConfiguration, enumParameterConfiguration };
