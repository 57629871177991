<template>
  <div class="pa-4">
    <v-row justify="space-between">
      <v-col class="text-h4" cols="12" sm="auto">{{ $vuetify.lang.t("$vuetify.configuration.titlePl") }}</v-col>
      <v-col cols="12" sm="auto">
        <v-btn :to="{ name: 'Dashboard' }" color="secondary" text large exact>
          <v-icon>mdi-arrow-left</v-icon>
          {{ $vuetify.lang.t("$vuetify.buttons.backToDashboard") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-tabs v-model="configurationTabs" background-color="primary" fixed-tabs dark>
            <v-tab href="#LAYOUT">{{ $vuetify.lang.t("$vuetify.configuration.layoutConfiguration.title") }}</v-tab>
            <v-tab href="#EMAIL">{{ $vuetify.lang.t("$vuetify.configuration.emailConfiguration.title") }}</v-tab>
            <v-tab href="#API" v-if="userInfo.main">{{ $vuetify.lang.t("$vuetify.configuration.apiConfiguration.title") }}</v-tab>
            <v-tab href="#PARAMETER" v-if="userInfo.main">{{ $vuetify.lang.t("$vuetify.configuration.parameterConfiguration.title") }}</v-tab>
            <v-tab href="#CONTENT">{{ $vuetify.lang.t("$vuetify.configuration.contentConfiguration.title") }}</v-tab>
            <v-tab href="#TABS">{{ $vuetify.lang.t("$vuetify.tabs.title") }}</v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="configurationTabs">
              <v-tab-item value="LAYOUT">
                <v-form ref="formLayout" @submit="handleLayoutConfigurationSubmit">
                  <v-row>
                    <v-col cols="12">
                      <div class="text-h6">{{ $vuetify.lang.t("$vuetify.configuration.layoutConfiguration.colors") }}</div>
                      <v-divider class="my-4"></v-divider>
                      <v-row>
                        <v-col cols="12" md="6">
                          <VAppColorPicker
                            id="primary"
                            name="primary"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.primary')"
                            v-model="layoutConfiguration.primary"
                          ></VAppColorPicker>
                        </v-col>
                        <v-col cols="12" md="6">
                          <VAppColorPicker
                            id="secondaryColor"
                            name="secondaryColor"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.secondary')"
                            v-model="layoutConfiguration.secondary"
                          ></VAppColorPicker>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <div class="text-h6">{{ $vuetify.lang.t("$vuetify.configuration.layoutConfiguration.images") }}</div>
                      <v-divider class="my-4"></v-divider>
                      <v-row>
                        <v-col cols="12" md="6">
                          <VAppImageInput
                            id="logoImage"
                            name="logoImage"
                            key="logoImage"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.logoImage')"
                            :helper="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.logoImageHelper', maxFileSizeDisplay)"
                            v-model="layoutConfiguration.logoImage"
                            hide-details="auto"
                          ></VAppImageInput>
                        </v-col>
                        <v-col cols="12" md="6">
                          <VAppImageInput
                            id="bannerImage"
                            name="bannerImage"
                            ref="bannerImage"
                            :label="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.bannerImage')"
                            :helper="$vuetify.lang.t('$vuetify.configuration.layoutConfiguration.bannerImageHelper', maxFileSizeDisplay)"
                            v-model="layoutConfiguration.bannerImage"
                            hide-details="auto"
                          ></VAppImageInput>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="EMAIL">
                <v-form ref="formEmail" @submit="handleEmailConfigurationSubmit">
                  <v-row>
                    <v-col cols="12" md="8" lg="9">
                      <VAppTextField
                        v-model="emailConfiguration.smtpServer"
                        name="smtpServer"
                        prepend-inner-icon="mdi-server"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpServer')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="4" lg="3">
                      <VAppTextField
                        v-model="emailConfiguration.smtpPort"
                        name="smtpPort"
                        prepend-inner-icon="mdi-door"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpPort')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <VAppTextField
                        v-model="emailConfiguration.smtpEmail"
                        name="smtpEmail"
                        prepend-inner-icon="mdi-at"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpEmail')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <VAppTextField
                        v-model="emailConfiguration.smtpPassword"
                        type="password"
                        name="smtpPassword"
                        prepend-inner-icon="mdi-lock"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.smtpPassword')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <VAppTextField
                        v-model="emailConfiguration.fromEmail"
                        name="fromEmail"
                        prepend-inner-icon="mdi-at"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.fromEmail')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="emailConfiguration.useTls"
                        :label="$vuetify.lang.t('$vuetify.configuration.emailConfiguration.useTls')"
                        true-value="true"
                        false-value="false"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row justify="space-between">
                    <v-col cols="12" sm="auto">
                      <v-btn color="primary" depressed left large @click="testSend">
                        <v-icon left dark>mdi-email-fast</v-icon>
                        {{ $vuetify.lang.t("$vuetify.configuration.emailConfiguration.testEmailSend.title") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="API">
                <v-form ref="formAPI" @submit="handleAPIConfigurationSubmit">
                  <v-row>
                    <v-col cols="12" md="12" lg="12">
                      <VAppTextField
                        v-model="apiConfiguration.pathServiceLecomModel"
                        name="pathServiceLecomModel"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.pathServiceLecomModel')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.pathServiceLecomAuthentication"
                        name="pathServiceLecomAuthentication"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.pathServiceLecomAuthentication')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.pathServiceLecomOpenProcess"
                        name="pathServiceLecomOpenProcess"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.pathServiceLecomOpenProcess')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.pathServiceLecomProcessInstances"
                        name="pathServiceLecomProcessInstances"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.pathServiceLecomProcessInstances')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.pathServiceLecomFormApp"
                        name="pathServiceLecomFormApp"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.pathServiceLecomFormApp')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.serviceLecomAuthenticationAdminLogin"
                        name="serviceLecomAuthenticationAdminLogin"
                        prepend-inner-icon="mdi-account"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.serviceLecomAuthenticationAdminLogin')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        type="password"
                        v-model="apiConfiguration.serviceLecomAuthenticationAdminPassword"
                        name="serviceLecomAuthenticationAdminPassword"
                        prepend-inner-icon="mdi-lock"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.serviceLecomAuthenticationAdminPassword')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.serviceLecomUserPublicLogin"
                        name="serviceLecomUserPublicLogin"
                        prepend-inner-icon="mdi-account"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.serviceLecomUserPublicLogin')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        type="password"
                        v-model="apiConfiguration.serviceLecomUserPublicPassword"
                        name="serviceLecomUserPublicPassword"
                        prepend-inner-icon="mdi-lock"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.serviceLecomUserPublicPassword')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.serviceLecomUserPublicId"
                        name="serviceLecomUserPublicId"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.serviceLecomUserPublicId')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="apiConfiguration.urlServiceLecomBpm"
                        name="urlServiceLecomBpm"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.apiConfiguration.urlServiceLecomBpm')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="PARAMETER">
                <v-form ref="formAPI" @submit="handleParameterConfigurationSubmit">
                  <v-row>
                    <v-col cols="12" md="4" lg="4">
                      <VAppSelectField
                        v-model="parameterConfiguration.authenticationType"
                        name="authenticationType"
                        prepend-inner-icon="mdi-arrow-down-drop-circle"
                        :items="listAuthentication"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.authenticationType')"
                        return-object
                        hide-details="auto"
                      ></VAppSelectField>
                    </v-col>
                    <v-col cols="12" md="8" lg="8" v-if="parameterConfiguration.authenticationType == 'Autenticação Portal + Validação'">
                      <VAppTextField
                        v-model="parameterConfiguration.loginParameterUrl"
                        name="loginParameterUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.loginParameterUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" v-if="parameterConfiguration.authenticationType == 'Autenticação Portal + Validação'">
                      <VAppTextField
                        v-model="parameterConfiguration.loginParameterApiUrl"
                        name="loginParameterApiUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.loginParameterApiUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" v-if="parameterConfiguration.authenticationType == 'Autenticação Portal + Validação'">
                      <VAppTextField
                        v-model="parameterConfiguration.loginParameterApiUser"
                        name="loginParameterApiUser"
                        prepend-inner-icon="mdi-account"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.loginParameterApiUser')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" v-if="parameterConfiguration.authenticationType == 'Autenticação Portal + Validação'">
                      <VAppTextField
                        type="password"
                        v-model="parameterConfiguration.loginParameterApiPassword"
                        name="loginParameterApiPassword"
                        prepend-inner-icon="mdi-lock"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.loginParameterApiPassword')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="8" lg="8" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceUrl"
                        name="oauthPkceUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceAuthorizeUrl"
                        name="oauthPkceAuthorizeUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceAuthorizeUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceTokenUrl"
                        name="oauthPkceTokenUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceTokenUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceUserInfoUrl"
                        name="oauthPkceUserInfoUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceUserInfoUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="63" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceLogoutUrl"
                        name="oauthPkceLogoutUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceLogoutUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkcePostLogoutRedirectUrl"
                        name="oauthPkcePostLogoutRedirectUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkcePostLogoutRedirectUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceRedirectUri"
                        name="oauthPkceRedirectUri"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceRedirectUri')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppSelectField
                        v-model="parameterConfiguration.oauthPkceLoginButtonEnabled"
                        name="oauthPkceLoginButtonEnabled"
                        prepend-inner-icon="mdi-cog"
                        :items="listLoginButtonEnabled"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceLoginButtonEnabled')"
                        return-object
                        hide-details="auto"
                      ></VAppSelectField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceLoginButtonText"
                        name="oauthPkceLoginButtonText"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceLoginButtonText')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceClientId"
                        name="oauthPkceClientId"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceClientId')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        type="password"
                        v-model="parameterConfiguration.oauthPkceClientSecret"
                        name="oauthPkceClientSecret"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceClientSecret')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceGrantType"
                        name="oauthPkceGrantType"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceGrantType')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceScope"
                        name="oauthPkceScope"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceScope')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Oauth + PKCE'">
                      <VAppTextField
                        v-model="parameterConfiguration.oauthPkceIdField"
                        name="oauthPkceIdField"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.oauthPkceIdField')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="8" lg="8" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceUrl"
                        name="govbrOauthPkceUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceAuthorizeUrl"
                        name="govbrOauthPkceAuthorizeUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceAuthorizeUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceTokenUrl"
                        name="govbrOauthPkceTokenUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceTokenUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceUserInfoUrl"
                        name="govbrOauthPkceUserInfoUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceUserInfoUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="63" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceLogoutUrl"
                        name="govbrOauthPkceLogoutUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceLogoutUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkcePostLogoutRedirectUrl"
                        name="govbrOauthPkcePostLogoutRedirectUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkcePostLogoutRedirectUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceRedirectUri"
                        name="govbrOauthPkceRedirectUri"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceRedirectUri')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppSelectField
                        v-model="parameterConfiguration.govbrOauthPkceLoginButtonEnabled"
                        name="govbrOauthPkceLoginButtonEnabled"
                        prepend-inner-icon="mdi-cog"
                        :items="listLoginButtonEnabled"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceLoginButtonEnabled')"
                        return-object
                        hide-details="auto"
                      ></VAppSelectField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceLoginButtonText"
                        name="govbrOauthPkceLoginButtonText"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceLoginButtonText')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceClientId"
                        name="govbrOauthPkceClientId"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceClientId')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        type="password"
                        v-model="parameterConfiguration.govbrOauthPkceClientSecret"
                        name="govbrOauthPkceClientSecret"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceClientSecret')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceGrantType"
                        name="govbrOauthPkceGrantType"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceGrantType')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceScope"
                        name="govbrOauthPkceScope"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceScope')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="3" lg="3" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrOauthPkceIdField"
                        name="govbrOauthPkceIdField"
                        prepend-inner-icon="mdi-cog"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrOauthPkceIdField')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>

                    <v-col cols="12" md="12" lg="12" v-if="parameterConfiguration.authenticationType == 'Gov.br'">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrServiceLevelsUrl"
                        name="govbrServiceLevelsUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrServiceLevelsUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" v-if="parameterConfiguration.authenticationType == 'Gov.br'" v-show="false">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrServiceCategoriesUrl"
                        name="govbrServiceCategoriesUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrServiceCategoriesUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" v-if="parameterConfiguration.authenticationType == 'Gov.br'" v-show="false">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrServiceReliabilitiesUrl"
                        name="govbrServiceReliabilitiesUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrServiceReliabilitiesUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" v-if="parameterConfiguration.authenticationType == 'Gov.br'" v-show="false">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrServiceCompaniesUrl"
                        name="govbrServiceCompaniesUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrServiceCompaniesUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" v-if="parameterConfiguration.authenticationType == 'Gov.br'" v-show="false">
                      <VAppTextField
                        v-model="parameterConfiguration.govbrServiceCompanyDetailUrl"
                        name="govbrServiceCompanyDetailUrl"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.govbrServiceCompanyDetailUrl')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="12" lg="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" lg="12">
                      <VAppTextField
                        v-model="parameterConfiguration.urlAmbienteFront"
                        name="urlAmbienteFront"
                        prepend-inner-icon="mdi-link"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.urlAmbienteFront')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="parameterConfiguration.apiTokenUser"
                        name="apiTokenUser"
                        prepend-inner-icon="mdi-account"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.apiTokenUser')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        type="password"
                        v-model="parameterConfiguration.apiTokenPassword"
                        name="apiTokenPassword"
                        prepend-inner-icon="mdi-lock"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.apiTokenPassword')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppSelectField
                        v-model="parameterConfiguration.authLayoutDirection"
                        name="authLayoutDirection"
                        prepend-inner-icon="mdi-swap-horizontal"
                        :items="listDirection"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.authLayoutDirection')"
                        return-object
                        hide-details="auto"
                      ></VAppSelectField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="parameterConfiguration.failedLoginCount"
                        name="failedLoginCount"
                        prepend-inner-icon="mdi-account-cancel"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.failedLoginCount')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="parameterConfiguration.appTitleClient"
                        name="appTitleClient"
                        prepend-inner-icon="mdi-account-cancel"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.appTitleClient')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <VAppTextField
                        v-model="parameterConfiguration.appTitle"
                        name="appTitle"
                        prepend-inner-icon="mdi-account-cancel"
                        :label="$vuetify.lang.t('$vuetify.configuration.parameterConfiguration.appTitle')"
                        hide-details="auto"
                      ></VAppTextField>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="CONTENT">
                <v-form ref="formContent" @submit="handleContentConfigurationSubmit">
                  <v-row>
                    <v-col cols="12">
                      <VAppTextArea
                        v-model="contentConfiguration.termsConditions"
                        name="termsConditions"
                        :label="$vuetify.lang.t('$vuetify.configuration.contentConfiguration.termsConditions')"
                        hide-details="auto"
                      ></VAppTextArea>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" sm="auto">
                      <v-btn color="success" type="submit" depressed right large>
                        <v-icon left dark>mdi-content-save</v-icon>
                        {{ $vuetify.lang.t("$vuetify.buttons.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item value="TABS">
                <v-row>
                  <v-col>
                    <div class="text-h6">{{ $vuetify.lang.t("$vuetify.configuration.tabConfiguration.title") }}</div>
                    <v-divider class="my-4"></v-divider>
                    <TabDataTable></TabDataTable>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  enumLayoutConfiguration,
  enumEmailConfiguration,
  enumContentConfiguration,
  enumAPIConfiguration,
  enumParameterConfiguration,
} from "@/enums/configurationKeys";
import layoutConfigurationApi from "@/api/layoutConfiguration";
import emailConfigurationApi from "@/api/emailConfiguration";
import apiConfigurationApi from "@/api/apiConfiguration";
import parameterConfigurationApi from "@/api/parameterConfiguration";
import documentApi from "@/api/document";
import TabDataTable from "@/components/datatables/Tab";
import tokenService from "@/services/token";

export default {
  name: "Configuration",
  components: { TabDataTable },
  data: () => ({
    configurationTabs: "LAYOUT",
    layoutConfiguration: {
      primary: null,
      secondaryColor: null,
      logoImage: null,
      bannerImage: null,
      userInfo: {},
    },
    emailConfiguration: {
      smtpServer: null,
      smtpPort: null,
      smtpEmail: null,
      smtpPassword: null,
      fromEmail: null,
      useTls: "false",
    },
    apiConfiguration: {
      pathServiceLecomModel: null,
      pathServiceLecomAuthentication: null,
      pathServiceLecomOpenProcess: null,
      pathServiceLecomProcessInstances: null,
      pathServiceLecomFormApp: null,
      serviceLecomAuthenticationAdminLogin: null,
      serviceLecomAuthenticationAdminPassword: null,
      serviceLecomUserPublicLogin: null,
      serviceLecomUserPublicPassword: null,
      serviceLecomUserPublicId: null,
      urlServiceLecomBpm: null,
    },
    parameterConfiguration: {
      authenticationType: "FORM",
      loginParameterUrl: null,
      loginParameterApiUrl: null,
      loginParameterApiUser: null,
      loginParameterApiPassword: null,
      oauthPkceUrl: null,
      oauthPkceAuthorizeUrl: null,
      oauthPkceTokenUrl: null,
      oauthPkceUserInfoUrl: null,
      oauthPkceLoginUrl: null,
      oauthPkceLogoutUrl: null,
      oauthPkcePostLogoutRedirectUrl: null,
      oauthPkceClientId: null,
      oauthPkceClientSecret: null,
      oauthPkceGrantType: null,
      oauthPkceScope: null,
      oauthPkceRedirectUri: null,
      oauthPkceLoginButtonEnabled: "Não",
      oauthPkceLoginButtonText: null,
      oauthPkceIdField: null,
      urlAmbienteFront: null,
      apiTokenUser: null,
      apiTokenPassword: null,
      authLayoutDirection: "Direita",
      failedLoginCount: null,
      appTitleClient: null,
      appTitle: null,
      govbrOauthPkceUrl: null,
      govbrOauthPkceAuthorizeUrl: null,
      govbrOauthPkceTokenUrl: null,
      govbrOauthPkceUserInfoUrl: null,
      govbrOauthPkceLoginUrl: null,
      govbrOauthPkceLogoutUrl: null,
      govbrOauthPkcePostLogoutRedirectUrl: null,
      govbrOauthPkceClientId: null,
      govbrOauthPkceClientSecret: null,
      govbrOauthPkceGrantType: null,
      govbrOauthPkceScope: null,
      govbrOauthPkceRedirectUri: null,
      govbrOauthPkceLoginButtonEnabled: "Não",
      govbrOauthPkceLoginButtonText: null,
      govbrOauthPkceIdField: null,
      govbrServiceLevelsUrl: null,
      govbrServiceCategoriesUrl: null,
      govbrServiceReliabilitiesUrl: null,
      govbrServiceCompaniesUrl: null,
      govbrServiceCompanyDetailUrl: null,
    },
    contentConfiguration: {
      termsConditions: null,
    },
    // listStatus: ["Ativo", "Inativo"],
    listAuthentication: ["Autenticação Portal", "Autenticação Portal + Validação", "Oauth + PKCE", "Gov.br"],
    listDirection: ["Esquerda", "Centro", "Direita"],
    listLoginButtonEnabled: ["Sim", "Não"],
  }),
  props: {
    activeTab: String,
  },
  computed: {
    userToken: () => {
      return tokenService.getToken();
    },
    routeKey: function () {
      // `this` aponta para a instância Vue da variável `vm`
      return this.$route.name + (this.$route.params.id ? this.$route.params.id : "");
    },
    maxFileSizeDisplay() {
      return process.env.VUE_APP_FILE_SIZE_LIMIT / 1048576;
    },
  },
  async created() {
    if (this.userToken) this.userInfo = tokenService.getDecodedToken().user;
    // If sent an active tab, show
    if (this.activeTab) this.configurationTabs = this.activeTab;
    // Show Overlay
    this.$emit("control-overlay", true);
    // Get Configuration Colors
    await layoutConfigurationApi
      .get()
      .then((configurationItems) => {
        // Set new colors configuration
        configurationItems.forEach((element) => {
          if (element.code === enumLayoutConfiguration.PRIMARYCOLOR && element.value) this.layoutConfiguration.primary = element.value;
          if (element.code === enumLayoutConfiguration.SECONDARYCOLOR && element.value) this.layoutConfiguration.secondary = element.value;
          if (element.code === enumContentConfiguration.TERMSCONDITIONS && element.value) this.contentConfiguration.termsConditions = element.value;
        });
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors.get"),
          text: error.message,
        });
      });
    // Get Current SMTP Values
    await emailConfigurationApi
      .get()
      .then((emailConfigurations) => {
        // Atualiza o email
        emailConfigurations.forEach((element) => {
          if (element.code === enumEmailConfiguration.SMTPSERVER) this.emailConfiguration.smtpServer = element.value;
          if (element.code === enumEmailConfiguration.SMTPPORT) this.emailConfiguration.smtpPort = element.value;
          if (element.code === enumEmailConfiguration.SMTPEMAIL) this.emailConfiguration.smtpEmail = element.value;
          if (element.code === enumEmailConfiguration.SMTPFROM) this.emailConfiguration.fromEmail = element.value;
          if (element.code === enumEmailConfiguration.SMTPTLS) this.emailConfiguration.useTls = element.value ? element.value : "false";
        });
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.emailConfiguration.errors.get"),
          text: error.message,
        });
      })
      .finally(() => {
        // Hide Overlay
        this.$emit("control-overlay", false);
      });

    await apiConfigurationApi
      .get()
      .then((apiConfigurations) => {
        // Atualiza o email
        apiConfigurations.forEach((element) => {
          if (element.code === enumAPIConfiguration.PATHSERVICELECOMMODEL) this.apiConfiguration.pathServiceLecomModel = element.value;
          if (element.code === enumAPIConfiguration.PATHSERVICELECOMAUTHENTICATION)
            this.apiConfiguration.pathServiceLecomAuthentication = element.value;
          if (element.code === enumAPIConfiguration.PATHSERVICELECOMOPENPROCESS) this.apiConfiguration.pathServiceLecomOpenProcess = element.value;
          if (element.code === enumAPIConfiguration.PATHSERVICELECOMPROCESSINSTANCES)
            this.apiConfiguration.pathServiceLecomProcessInstances = element.value;
          if (element.code === enumAPIConfiguration.PATHSERVICELECOMFORMAPP) this.apiConfiguration.pathServiceLecomFormApp = element.value;
          if (element.code === enumAPIConfiguration.SERVICELECOMAUTHENTICATIONADMINLOGIN)
            this.apiConfiguration.serviceLecomAuthenticationAdminLogin = element.value;
          if (element.code === enumAPIConfiguration.SERVICELECOMUSERPUBLICLOGIN) this.apiConfiguration.serviceLecomUserPublicLogin = element.value;
          if (element.code === enumAPIConfiguration.SERVICELECOMUSERPUBLICID) this.apiConfiguration.serviceLecomUserPublicId = element.value;
          if (element.code === enumAPIConfiguration.URLSERVICELECOMBPM) this.apiConfiguration.urlServiceLecomBpm = element.value;
        });
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.apiConfiguration.errors.get"),
          text: error.message,
        });
      })
      .finally(() => {
        // Hide Overlay
        this.$emit("control-overlay", false);
      });

    await parameterConfigurationApi
      .get()
      .then((parameterConfigurations) => {
        // Atualiza o email
        parameterConfigurations.forEach((element) => {
          if (element.code === enumParameterConfiguration.AUTHENTICATIONTYPE) {
            if (element.value === "FORM") {
              this.parameterConfiguration.authenticationType = "Autenticação Portal";
            }

            if (element.value === "FORM_VALIDATE") {
              this.parameterConfiguration.authenticationType = "Autenticação Portal + Validação";
            }

            if (element.value === "OAUTH") {
              this.parameterConfiguration.authenticationType = "Oauth";
            }

            if (element.value === "OAUTH_PKCE") {
              this.parameterConfiguration.authenticationType = "Oauth + PKCE";
            }

            if (element.value === "GOV_BR") {
              this.parameterConfiguration.authenticationType = "Gov.br";
            }
          }

          if (element.code === enumParameterConfiguration.LOGINPARAMETERURL) this.parameterConfiguration.loginParameterUrl = element.value;
          if (element.code === enumParameterConfiguration.LOGINPARAMETERAPIURL) this.parameterConfiguration.loginParameterApiUrl = element.value;
          if (element.code === enumParameterConfiguration.LOGINPARAMETERAPIUSER) this.parameterConfiguration.loginParameterApiUser = element.value;

          if (element.code === enumParameterConfiguration.OAUTHPKCEURL) this.parameterConfiguration.oauthPkceUrl = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCEAUTHORIZEURL) this.parameterConfiguration.oauthPkceAuthorizeUrl = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCETOKENURL) this.parameterConfiguration.oauthPkceTokenUrl = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCEUSERINFOURL) this.parameterConfiguration.oauthPkceUserInfoUrl = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCELOGINURL) this.parameterConfiguration.oauthPkceLoginUrl = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCELOGOUTURL) this.parameterConfiguration.oauthPkceLogoutUrl = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCEPOSTLOGOUTREDIRECTURL) this.parameterConfiguration.oauthPkcePostLogoutRedirectUrl = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCECLIENTID) this.parameterConfiguration.oauthPkceClientId = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCECLIENTSECRET) this.parameterConfiguration.oauthPkceClientSecret = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCEGRANTTYPE) this.parameterConfiguration.oauthPkceGrantType = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCESCOPE) this.parameterConfiguration.oauthPkceScope = element.value;
          if (element.code === enumParameterConfiguration.OAUTHPKCEREDIRECTURI) this.parameterConfiguration.oauthPkceRedirectUri = element.value;

          if (element.code === enumParameterConfiguration.OAUTHPKCELOGINBUTTONENABLED) {
            this.parameterConfiguration.oauthPkceLoginButtonEnabled = element.value == "true" ? "Sim" : "Não";
          }

          if (element.code === enumParameterConfiguration.OAUTHPKCELOGINBUTTONTEXT) {
            this.parameterConfiguration.oauthPkceLoginButtonText = element.value;
          }

          if (element.code === enumParameterConfiguration.OAUTHPKCEIDFIELD) this.parameterConfiguration.oauthPkceIdField = element.value;

          if (element.code === enumParameterConfiguration.URLAMBIENTEFRONT) this.parameterConfiguration.urlAmbienteFront = element.value;
          if (element.code === enumParameterConfiguration.APITOKENUSER) this.parameterConfiguration.apiTokenUser = element.value;
          if (element.code === enumParameterConfiguration.AUTHLAYOUTDIRECTION) {
            this.parameterConfiguration.authLayoutDirection = element.value == "LEFT" ? "Esquerda" : element.value == "CENTER" ? "Centro" : "Direita";
          }
          if (element.code === enumParameterConfiguration.FAILEDLOGINCOUNT) this.parameterConfiguration.failedLoginCount = element.value;
          if (element.code === enumParameterConfiguration.APPTITLECLIENT) this.parameterConfiguration.appTitleClient = element.value;
          if (element.code === enumParameterConfiguration.APPTITLE) this.parameterConfiguration.appTitle = element.value;

          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCEURL) this.parameterConfiguration.govbrOauthPkceUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCEAUTHORIZEURL) this.parameterConfiguration.govbrOauthPkceAuthorizeUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCETOKENURL) this.parameterConfiguration.govbrOauthPkceTokenUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCEUSERINFOURL) this.parameterConfiguration.govbrOauthPkceUserInfoUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCELOGINURL) this.parameterConfiguration.govbrOauthPkceLoginUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCELOGOUTURL) this.parameterConfiguration.govbrOauthPkceLogoutUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCEPOSTLOGOUTREDIRECTURL) this.parameterConfiguration.govbrOauthPkcePostLogoutRedirectUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCECLIENTID) this.parameterConfiguration.govbrOauthPkceClientId = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCECLIENTSECRET) this.parameterConfiguration.govbrOauthPkceClientSecret = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCEGRANTTYPE) this.parameterConfiguration.govbrOauthPkceGrantType = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCESCOPE) this.parameterConfiguration.govbrOauthPkceScope = element.value;
          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCEREDIRECTURI) this.parameterConfiguration.govbrOauthPkceRedirectUri = element.value;

          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCELOGINBUTTONENABLED) {
            this.parameterConfiguration.govbrOauthPkceLoginButtonEnabled = element.value == "true" ? "Sim" : "Não";
          }

          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCELOGINBUTTONTEXT) {
            this.parameterConfiguration.govbrOauthPkceLoginButtonText = element.value;
          }

          if (element.code === enumParameterConfiguration.GOVBROAUTHPKCEIDFIELD) this.parameterConfiguration.govbrOauthPkceIdField = element.value;

          if (element.code === enumParameterConfiguration.GOVBRSERVICELEVELSURL) this.parameterConfiguration.govbrServiceLevelsUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBRSERVICECATEGORIESURL) this.parameterConfiguration.govbrServiceCategoriesUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBRSERVICERELIABILITIESURL) this.parameterConfiguration.govbrServiceReliabilitiesUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBRSERVICECOMPANIESURL) this.parameterConfiguration.govbrServiceCompaniesUrl = element.value;
          if (element.code === enumParameterConfiguration.GOVBRSERVICECOMPANYDETAILURL) this.parameterConfiguration.govbrServiceCompanyDetailUrl = element.value;
        });
      })
      .catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.parameterConfiguration.errors.get"),
          text: error.message,
        });
      })
      .finally(() => {
        // Hide Overlay
        this.$emit("control-overlay", false);
      });
  },
  methods: {
    async handleLayoutConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload Colors
      if (this.layoutConfiguration.primary)
        await this.updateLayoutColor(enumLayoutConfiguration.PRIMARYCOLOR, this.layoutConfiguration.primary, "primary", errorControl);
      if (this.layoutConfiguration.secondary)
        await this.updateLayoutColor(enumLayoutConfiguration.SECONDARYCOLOR, this.layoutConfiguration.secondary, "secondary", errorControl);
      // Upload Images
      if (this.layoutConfiguration.logoImage)
        await this.updateDocument(enumLayoutConfiguration.LOGOIMG, this.layoutConfiguration.logoImage, "logoImage", errorControl);
      if (this.layoutConfiguration.bannerImage)
        await this.updateDocument(enumLayoutConfiguration.BANNERIMG, this.layoutConfiguration.bannerImage, "bannerImage", errorControl);
      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.layoutConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.layoutConfiguration.notifications.success.message"),
        });
      }
      // Reload Page after 3 minutes
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    async handleEmailConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload Email
      if (this.emailConfiguration.smtpServer)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPSERVER, this.emailConfiguration.smtpServer, "smtpServer", errorControl);
      if (this.emailConfiguration.smtpPort)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPPORT, this.emailConfiguration.smtpPort, "smtpPort", errorControl);
      if (this.emailConfiguration.smtpEmail)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPEMAIL, this.emailConfiguration.smtpEmail, "smtpEmail", errorControl);
      if (this.emailConfiguration.smtpPassword)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPPASS, this.emailConfiguration.smtpPassword, "smtpPassword", errorControl);
      if (this.emailConfiguration.fromEmail)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPFROM, this.emailConfiguration.fromEmail, "fromEmail", errorControl);
      if (this.emailConfiguration.useTls)
        await this.updateEmailConfiguration(enumEmailConfiguration.SMTPTLS, this.emailConfiguration.useTls, "useTls", errorControl);
      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.message"),
        });
      }
      // Show Overlay
      this.$emit("control-overlay", false);
    },
    async handleAPIConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload API
      if (this.apiConfiguration.pathServiceLecomModel)
        await this.updateApiConfiguration(
          enumAPIConfiguration.PATHSERVICELECOMMODEL,
          this.apiConfiguration.pathServiceLecomModel,
          "pathServiceLecomModel",
          errorControl
        );
      if (this.apiConfiguration.pathServiceLecomAuthentication)
        await this.updateApiConfiguration(
          enumAPIConfiguration.PATHSERVICELECOMAUTHENTICATION,
          this.apiConfiguration.pathServiceLecomAuthentication,
          "pathServiceLecomAuthentication",
          errorControl
        );
      if (this.apiConfiguration.pathServiceLecomOpenProcess)
        await this.updateApiConfiguration(
          enumAPIConfiguration.PATHSERVICELECOMOPENPROCESS,
          this.apiConfiguration.pathServiceLecomOpenProcess,
          "pathServiceLecomOpenProcess",
          errorControl
        );
      if (this.apiConfiguration.pathServiceLecomProcessInstances)
        await this.updateApiConfiguration(
          enumAPIConfiguration.PATHSERVICELECOMPROCESSINSTANCES,
          this.apiConfiguration.pathServiceLecomProcessInstances,
          "pathServiceLecomProcessInstances",
          errorControl
        );
      if (this.apiConfiguration.pathServiceLecomFormApp)
        await this.updateApiConfiguration(
          enumAPIConfiguration.PATHSERVICELECOMFORMAPP,
          this.apiConfiguration.pathServiceLecomFormApp,
          "pathServiceLecomFormApp",
          errorControl
        );
      if (this.apiConfiguration.serviceLecomAuthenticationAdminLogin)
        await this.updateApiConfiguration(
          enumAPIConfiguration.SERVICELECOMAUTHENTICATIONADMINLOGIN,
          this.apiConfiguration.serviceLecomAuthenticationAdminLogin,
          "serviceLecomAuthenticationAdminLogin",
          errorControl
        );
      if (this.apiConfiguration.serviceLecomAuthenticationAdminPassword) {
        await this.updateApiConfiguration(
          enumAPIConfiguration.SERVICELECOMAUTHENTICATIONADMINPASSWORD,
          this.apiConfiguration.serviceLecomAuthenticationAdminPassword,
          "serviceLecomAuthenticationAdminPassword",
          errorControl
        );
        this.apiConfiguration.serviceLecomAuthenticationAdminPassword = null;
      }
      if (this.apiConfiguration.serviceLecomUserPublicLogin)
        await this.updateApiConfiguration(
          enumAPIConfiguration.SERVICELECOMUSERPUBLICLOGIN,
          this.apiConfiguration.serviceLecomUserPublicLogin,
          "serviceLecomUserPublicLogin",
          errorControl
        );
      if (this.apiConfiguration.serviceLecomUserPublicPassword) {
        await this.updateApiConfiguration(
          enumAPIConfiguration.SERVICELECOMUSERPUBLICPASS,
          this.apiConfiguration.serviceLecomUserPublicPassword,
          "serviceLecomUserPublicPassword",
          errorControl
        );
        this.apiConfiguration.serviceLecomUserPublicPassword = null;
      }
      if (this.apiConfiguration.serviceLecomUserPublicId)
        await this.updateApiConfiguration(
          enumAPIConfiguration.SERVICELECOMUSERPUBLICID,
          this.apiConfiguration.serviceLecomUserPublicId,
          "serviceLecomUserPublicId",
          errorControl
        );
      if (this.apiConfiguration.urlServiceLecomBpm)
        await this.updateApiConfiguration(
          enumAPIConfiguration.URLSERVICELECOMBPM,
          this.apiConfiguration.urlServiceLecomBpm,
          "urlServiceLecomBpm",
          errorControl
        );

      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.apiConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.apiConfiguration.notifications.success.message"),
        });
      }
      // Show Overlay
      this.$emit("control-overlay", false);
    },
    async handleParameterConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload Parameter
      if (this.parameterConfiguration.authenticationType) {
        var authentication = "FORM";

        if (this.parameterConfiguration.authenticationType === "Autenticação Portal") {
          authentication = "FORM";
        }

        if (this.parameterConfiguration.authenticationType === "Autenticação Portal + Validação") {
          authentication = "FORM_VALIDATE";
        }

        if (this.parameterConfiguration.authenticationType === "Oauth") {
          authentication = "OAUTH";
        }

        if (this.parameterConfiguration.authenticationType === "Oauth + PKCE") {
          authentication = "OAUTH_PKCE";
        }

        if (this.parameterConfiguration.authenticationType === "Gov.br") {
          authentication = "GOV_BR";
        }

        await this.updateParameterConfiguration(enumParameterConfiguration.AUTHENTICATIONTYPE, authentication, "authenticationType", errorControl);
      }

      if (this.parameterConfiguration.loginParameterUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.LOGINPARAMETERURL,
          this.parameterConfiguration.loginParameterUrl,
          "loginParameterUrl",
          errorControl
        );

      if (this.parameterConfiguration.loginParameterApiUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.LOGINPARAMETERAPIURL,
          this.parameterConfiguration.loginParameterApiUrl,
          "loginParameterApiUrl",
          errorControl
        );

      if (this.parameterConfiguration.loginParameterApiUser)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.LOGINPARAMETERAPIUSER,
          this.parameterConfiguration.loginParameterApiUser,
          "loginParameterApiUser",
          errorControl
        );

      if (this.parameterConfiguration.loginParameterApiPassword) {
        await this.updateParameterConfiguration(
          enumParameterConfiguration.LOGINPARAMETERAPIPASSWORD,
          this.parameterConfiguration.loginParameterApiPassword,
          "loginParameterApiPassword",
          errorControl
        );
        this.parameterConfiguration.loginParameterApiPassword = null;
      }

      if (this.parameterConfiguration.oauthPkceUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCEURL,
          this.parameterConfiguration.oauthPkceUrl,
          "oauthPkceUrl",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceAuthorizeUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCEAUTHORIZEURL,
          this.parameterConfiguration.oauthPkceAuthorizeUrl,
          "oauthPkceAuthorizeUrl",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceTokenUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCETOKENURL,
          this.parameterConfiguration.oauthPkceTokenUrl,
          "oauthPkceTokenUrl",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceUserInfoUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCEUSERINFOURL,
          this.parameterConfiguration.oauthPkceUserInfoUrl,
          "oauthPkceUserInfoUrl",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceLoginUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCELOGINURL,
          this.parameterConfiguration.oauthPkceLoginUrl,
          "oauthPkceLoginUrl",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceLogoutUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCELOGOUTURL,
          this.parameterConfiguration.oauthPkceLogoutUrl,
          "oauthPkceLogoutUrl",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkcePostLogoutRedirectUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCEPOSTLOGOUTREDIRECTURL,
          this.parameterConfiguration.oauthPkcePostLogoutRedirectUrl,
          "oauthPkcePostLogoutRedirectUrl",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceClientId)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCECLIENTID,
          this.parameterConfiguration.oauthPkceClientId,
          "oauthPkceClientId",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceClientSecret)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCECLIENTSECRET,
          this.parameterConfiguration.oauthPkceClientSecret,
          "oauthPkceClientSecret",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceGrantType)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCEGRANTTYPE,
          this.parameterConfiguration.oauthPkceGrantType,
          "oauthPkceGrantType",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceScope)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCESCOPE,
          this.parameterConfiguration.oauthPkceScope,
          "oauthPkceScope",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceRedirectUri)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCEREDIRECTURI,
          this.parameterConfiguration.oauthPkceRedirectUri,
          "oauthPkceRedirectUri",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceLoginButtonEnabled)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCELOGINBUTTONENABLED,
          this.parameterConfiguration.oauthPkceLoginButtonEnabled == "Sim" ? "true" : "false",
          "oauthPkceLoginButtonEnabled",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceLoginButtonText)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCELOGINBUTTONTEXT,
          this.parameterConfiguration.oauthPkceLoginButtonText,
          "oauthPkceLoginButtonText",
          errorControl
        );

      if (this.parameterConfiguration.oauthPkceIdField)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.OAUTHPKCEIDFIELD,
          this.parameterConfiguration.oauthPkceIdField,
          "oauthPkceIdField",
          errorControl
        );

      if (this.parameterConfiguration.urlAmbienteFront)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.URLAMBIENTEFRONT,
          this.parameterConfiguration.urlAmbienteFront,
          "urlAmbienteFront",
          errorControl
        );

      if (this.parameterConfiguration.apiTokenUser)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.APITOKENUSER,
          this.parameterConfiguration.apiTokenUser,
          "apiTokenUser",
          errorControl
        );

      if (this.parameterConfiguration.apiTokenPassword) {
        await this.updateParameterConfiguration(
          enumParameterConfiguration.APITOKENPASSWORD,
          this.parameterConfiguration.apiTokenPassword,
          "apiTokenPassword",
          errorControl
        );
        this.parameterConfiguration.apiTokenPassword = null;
      }

      if (this.parameterConfiguration.authLayoutDirection)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.AUTHLAYOUTDIRECTION,
          this.parameterConfiguration.authLayoutDirection == "Esquerda"
            ? "LEFT"
            : this.parameterConfiguration.authLayoutDirection == "Centro"
              ? "CENTER"
              : "RIGHT",
          "authLayoutDirection",
          errorControl
        );

      if (this.parameterConfiguration.failedLoginCount)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.FAILEDLOGINCOUNT,
          this.parameterConfiguration.failedLoginCount,
          "failedLoginCount",
          errorControl
        );

      if (this.parameterConfiguration.appTitleClient)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.APPTITLECLIENT,
          this.parameterConfiguration.appTitleClient,
          "appTitleClient",
          errorControl
        );

      if (this.parameterConfiguration.appTitle)
        await this.updateParameterConfiguration(enumParameterConfiguration.APPTITLE, this.parameterConfiguration.appTitle, "appTitle", errorControl);

      if (this.parameterConfiguration.govbrOauthPkceUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCEURL,
          this.parameterConfiguration.govbrOauthPkceUrl,
          "govbrOauthPkceUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceAuthorizeUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCEAUTHORIZEURL,
          this.parameterConfiguration.govbrOauthPkceAuthorizeUrl,
          "govbrOauthPkceAuthorizeUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceTokenUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCETOKENURL,
          this.parameterConfiguration.govbrOauthPkceTokenUrl,
          "govbrOauthPkceTokenUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceUserInfoUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCEUSERINFOURL,
          this.parameterConfiguration.govbrOauthPkceUserInfoUrl,
          "govbrOauthPkceUserInfoUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceLoginUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCELOGINURL,
          this.parameterConfiguration.govbrOauthPkceLoginUrl,
          "govbrOauthPkceLoginUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceLogoutUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCELOGOUTURL,
          this.parameterConfiguration.govbrOauthPkceLogoutUrl,
          "govbrOauthPkceLogoutUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkcePostLogoutRedirectUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCEPOSTLOGOUTREDIRECTURL,
          this.parameterConfiguration.govbrOauthPkcePostLogoutRedirectUrl,
          "govbrOauthPkcePostLogoutRedirectUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceClientId)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCECLIENTID,
          this.parameterConfiguration.govbrOauthPkceClientId,
          "govbrOauthPkceClientId",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceClientSecret)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCECLIENTSECRET,
          this.parameterConfiguration.govbrOauthPkceClientSecret,
          "govbrOauthPkceClientSecret",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceGrantType)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCEGRANTTYPE,
          this.parameterConfiguration.govbrOauthPkceGrantType,
          "govbrOauthPkceGrantType",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceScope)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCESCOPE,
          this.parameterConfiguration.govbrOauthPkceScope,
          "govbrOauthPkceScope",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceRedirectUri)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCEREDIRECTURI,
          this.parameterConfiguration.govbrOauthPkceRedirectUri,
          "govbrOauthPkceRedirectUri",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceLoginButtonEnabled)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCELOGINBUTTONENABLED,
          this.parameterConfiguration.govbrOauthPkceLoginButtonEnabled == "Sim" ? "true" : "false",
          "govbrOauthPkceLoginButtonEnabled",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceLoginButtonText)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCELOGINBUTTONTEXT,
          this.parameterConfiguration.govbrOauthPkceLoginButtonText,
          "govbrOauthPkceLoginButtonText",
          errorControl
        );

      if (this.parameterConfiguration.govbrOauthPkceIdField)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBROAUTHPKCEIDFIELD,
          this.parameterConfiguration.govbrOauthPkceIdField,
          "govbrOauthPkceIdField",
          errorControl
        );

      if (this.parameterConfiguration.govbrServiceLevelsUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBRSERVICELEVELSURL,
          this.parameterConfiguration.govbrServiceLevelsUrl,
          "govbrServiceLevelsUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrServiceCategoriesUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBRSERVICECATEGORIESURL,
          this.parameterConfiguration.govbrServiceCategoriesUrl,
          "govbrServiceCategoriesUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrServiceReliabilitiesUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBRSERVICERELIABILITIESURL,
          this.parameterConfiguration.govbrServiceReliabilitiesUrl,
          "govbrServiceReliabilitiesUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrServiceCompaniesUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBRSERVICECOMPANIESURL,
          this.parameterConfiguration.govbrServiceCompaniesUrl,
          "govbrServiceCompaniesUrl",
          errorControl
        );

      if (this.parameterConfiguration.govbrServiceCompanyDetailUrl)
        await this.updateParameterConfiguration(
          enumParameterConfiguration.GOVBRSERVICECOMPANYDETAILURL,
          this.parameterConfiguration.govbrServiceCompanyDetailUrl,
          "govbrServiceCompanyDetailUrl",
          errorControl
        );

      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.parameterConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.parameterConfiguration.notifications.success.message"),
        });
      }
      // Show Overlay
      this.$emit("control-overlay", false);
    },

    async handleContentConfigurationSubmit(element) {
      // Show Overlay
      this.$emit("control-overlay", true);
      // Prevent default action
      element.preventDefault();
      // Error Control
      let errorControl = {
        hasErrors: false,
      };
      // Upload Colors
      if (this.contentConfiguration.termsConditions)
        await this.updateContentConfiguration(
          enumContentConfiguration.TERMSCONDITIONS,
          this.contentConfiguration.termsConditions,
          "termsCondition",
          errorControl
        );
      if (!errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "success",
          title: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.notifications.success.message"),
        });
      }
      // Show Overlay
      this.$emit("control-overlay", false);
    },
    async updateLayoutColor(code, value, message) {
      await layoutConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors." + message),
          text: error.message,
        });
      });
    },
    async updateDocument(code, value, errorMessage, errorControl) {
      await documentApi.upload(value, value.name, code).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.layoutConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
    async updateEmailConfiguration(code, value, errorMessage, errorControl) {
      await emailConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.emailConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
    async updateApiConfiguration(code, value, errorMessage, errorControl) {
      await apiConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.apiConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
    async updateParameterConfiguration(code, value, errorMessage, errorControl) {
      await parameterConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.parameterConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
    async updateContentConfiguration(code, value, errorMessage, errorControl) {
      await layoutConfigurationApi.save(code, value).catch((error) => {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.api.contentConfiguration.errors." + errorMessage),
          text: error.message,
        });
        errorControl.hasErrors = true;
      });
    },
    async testSend(element) {
      this.$emit("control-overlay", true);

      element.preventDefault();

      let errorControl = {
        hasErrors: false,
      };

      if (
        !this.emailConfiguration.smtpServer ||
        !this.emailConfiguration.smtpPort ||
        !this.emailConfiguration.smtpEmail ||
        !this.emailConfiguration.fromEmail
      ) {
        errorControl.hasErrors = true;
      }

      if (errorControl.hasErrors) {
        this.$notify({
          group: "application",
          type: "error",
          title: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.testEmailSend.notifications.error.title"),
          text: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.testEmailSend.notifications.error.message"),
        });
        this.$emit("control-overlay", false);
        return;
      }

      await emailConfigurationApi
        .testSend(this.emailConfiguration)
        .then(() => {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.testEmailSend.notifications.success.title"),
            text: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.testEmailSend.notifications.success.message"),
          });
        })
        .catch(() => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.testEmailSend.notifications.error.title"),
            text: this.$vuetify.lang.t("$vuetify.configuration.emailConfiguration.testEmailSend.notifications.error.testMessage"),
          });
          errorControl.hasErrors = true;
        })
        .finally(() => {
          this.$emit("control-overlay", false);
        });
    },
  },
};
</script>
