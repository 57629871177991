<template>
  <div>
    <v-card>
      <v-card-title>
        <v-text-field v-model="searchUser" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        class="stripped-datatable elevation-1"
        :headers="tableHeaders"
        :items="listUsers"
        :loading="loadingTable"
        :items-per-page="15"
        :server-items-length="totalRecords"
        :options.sync="pagination"
        :footer-props="{ itemsPerPageOptions: [5, 10, 15, 25, 50] }"
        @update:options="loadUserTable"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :to="{ name: 'RegisterUsers', params: { id: item.id } }" v-bind="attrs" v-on="on" small icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.editUser") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-1" color="blue darken-2" @click="showProcessDialog(item)" small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-view-list</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.process") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!item.admin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" color="blue darken-2" @click="showPermissionDialog(item)" small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-shield-check</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.permissions") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!item.admin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-1" color="blue darken-2" @click="showPermissionDialogGroup(item)" small icon v-bind="attrs" v-on="on">
                <v-icon>mdi-account-multiple-check</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.permissions") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="(item.status.code === 'USU_PENDENTE' || item.status.code === 'USU_PENDENTE_APROVACAO' || item.status.code === 'USU_SUSPENSO') && loggedUser.admin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                color="green"
                @click="
                  userConfirmation = item;
                  approveConfirmDialog = true;
                "
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.approveUser") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.status.code === 'USU_BLOQUEADO' && loggedUser.admin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                color="green"
                @click="
                  userConfirmation = item;
                  unlockConfirmDialog = true;
                "
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-lock-open</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.unlockUser") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-else-if="item.status.code !== 'USU_SUSPENSO'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                color="red"
                @click="
                  userConfirmation = item;
                  removeConfirmDialog = true;
                "
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.user.removeUser") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.status.title="{ item }">
          {{ $vuetify.lang.t("$vuetify.user.status." + item.status.code) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="approveConfirmDialog" max-width="500">
      <v-card v-if="userConfirmation.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.approveUser") }}</v-card-title>
        <v-card-text class="pt-4">
          <p v-html="$vuetify.lang.t('$vuetify.user.approveDialogContent', userConfirmation.name)"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="approveConfirmDialog = false">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="approveUser(userConfirmation.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.approve") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unlockConfirmDialog" max-width="500">
      <v-card v-if="userConfirmation.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.unlockUser") }}</v-card-title>
        <v-card-text class="pt-4">
          <p v-html="$vuetify.lang.t('$vuetify.user.unlockDialogContent', userConfirmation.name)"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="unlockConfirmDialog = false">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="unlockUser(userConfirmation.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.unlock") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeConfirmDialog" max-width="500">
      <v-card v-if="userConfirmation.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.removeUser") }}</v-card-title>
        <v-card-text class="pt-4">
          <p v-html="$vuetify.lang.t('$vuetify.user.removeDialogContent', userConfirmation.name)"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="removeConfirmDialog = false">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="red" text @click="removeUser(userConfirmation.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.suspend") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permissionsDialog" max-width="600" scrollable>
      <v-card v-if="userPermissions.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.permissions") }}</v-card-title>
        <v-card-text class="pt-4 content-scroll">
          <v-skeleton-loader v-if="!listServices" type="text@3"></v-skeleton-loader>
          <v-list flat subheader two-line v-else>
            <v-subheader v-html="$vuetify.lang.t('$vuetify.user.setPermissionsContent', userPermissions.name)"></v-subheader>
            <v-list-item-group v-model="selectedPermissions" multiple active-class="blue lighten-5 primary--text">
              <v-card-title>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
                <v-list-item-action>
                  <v-checkbox v-model="selectAll"></v-checkbox>
                </v-list-item-action>
              </v-card-title>

              <template v-for="(serviceObj, index) in filteredListServices">
                <v-list-item :key="serviceObj.titleFormLecom" :value="serviceObj.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ serviceObj.title }}</v-list-item-title>
                      <v-list-item-subtitle>{{ serviceObj.titleFormLecom }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">
                        {{ active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < listServices.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closePermissionDialog">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="savePermissions(userPermissions.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="permissionsDialogGroup" max-width="600" scrollable>
      <v-card v-if="userPermissionsGroup.id">
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.permissions") }}</v-card-title>
        <v-card-text class="pt-4 content-scroll">
          <v-skeleton-loader v-if="!listGroups" type="text@3"></v-skeleton-loader>
          <v-list flat subheader two-line v-else>
            <v-subheader v-html="$vuetify.lang.t('$vuetify.user.setPermissionsContent', userPermissionsGroup.name)"></v-subheader>
            <v-list-item-group v-model="selectedPermissionsGroup" multiple active-class="blue lighten-5 primary--text">
              <v-card-title>
                <v-text-field v-model="searchGroup" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
                <v-list-item-action>
                  <v-checkbox v-model="selectAllGroup"></v-checkbox>
                </v-list-item-action>
              </v-card-title>

              <template v-for="(groupObj, index) in filteredListGroups">
                <v-list-item :key="groupObj.description" :value="groupObj.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>{{ groupObj.description }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">
                        {{ active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index < listGroups.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closePermissionDialogGroup">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="savePermissionsGroup(userPermissionsGroup.id)">
            {{ $vuetify.lang.t("$vuetify.buttons.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="processDialog" max-width="600" scrollable>
      <v-card>
        <v-card-title class="secondary white--text">{{ $vuetify.lang.t("$vuetify.user.process") }}</v-card-title>
        <v-card-text class="pt-4 content-scroll">
          <v-skeleton-loader v-if="!listProcess" type="table"></v-skeleton-loader>

          <v-card outlined>
            <v-card-title class="align-start">
              <span class="font-weight-semibold">Estatística dos processos</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle class="mb-1 mt-n4">
              <span
                class="font-weight-semibold text--primary me-1"
                v-html="$vuetify.lang.t('$vuetify.user.processesOpenedByUser', userProcess.name)"
              ></span>
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="3" class="d-flex align-center">
                  <v-avatar size="35" :color="data.color" rounded class="elevation-0">
                    <v-icon dark color="white" size="25">{{ data.icon }}</v-icon>
                  </v-avatar>
                  <div class="ms-2">
                    <p class="text-xl mb-0">
                      {{ data.title }}
                    </p>
                    <h3 class="text-xl font-weight-semibold">
                      {{ data.total }}
                    </h3>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card-title>
            <v-text-field
              v-model="searchProcess"
              append-icon="mdi-magnify"
              :label="$vuetify.lang.t('$vuetify.user.searchProcess')"
              classe="expanding-search closed"
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-if="listProcess"
            class="stripped-datatable mt-8"
            :headers="tableHeadersProcess"
            :items="filteredListProcess"
            :items-per-page="5"
            item-key="processNo"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | formatDate }}
            </template>
          </v-data-table>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeProcessDialog">
            {{ $vuetify.lang.t("$vuetify.buttons.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.v-btn .v-icon,
.v-list-item--link .v-icon {
  margin-right: 0;
}
</style>

<script>
import moment from "moment";
import userApi from "@/api/user";
import serviceApi from "@/api/service";
import groupApi from "@/api/group";
import lecomApi from "@/api/lecom";
import tokenService from "@/services/token";
import layoutConfigurationApi from "@/api/layoutConfiguration";
import { enumContentConfiguration } from "@/enums/configurationKeys";

import { userPagination } from "@/constants/pagination.js";
import { userTableId } from "@/constants/tableIds.js";
import localStorageHelper from "@/helpers/localStorageHelper.js";

export default {
  name: "UserList",
  data: () => ({
    listUsers: [],
    listServices: [],
    listProcess: [],
    selectedPermissions: [],
    selectedPermissionsGroup: [],
    totalRecords: 0,
    loadingTable: false,
    approveConfirmDialog: false,
    approveConfirmDialogGroup: false,
    removeConfirmDialog: false,
    removeConfirmDialogGroup: false,
    permissionsDialog: false,
    permissionsDialogGroup: false,
    unlockConfirmDialog: false,
    processDialog: false,
    userConfirmation: {},
    userPermissions: {},
    userPermissionsGroup: {},
    userProcess: {},
    loggedUser: null,
    search: "",
    searchUser: "",
    searchGroup: "",
    searchProcess: "",
    selectAll: false,
    selectAllGroup: false,
    newOpenModalService: 0,
    businessNameLabel: "",
    listGroups: [],
    newOpenModalGroup: 0,

    pagination: null,
    tableId: `${userTableId}-pref-pag`,
    searchId: `${userTableId}-pref-pag-search`,
  }),
  watch: {
    searchUser: {
      deep: true,
      handler(newVal, oldVal) {
        if (!oldVal) {
          if (!localStorageHelper.get(this.searchId)) {
            localStorageHelper.set(this.searchId, oldVal || "");
          }
        } else {
          localStorageHelper.set(this.searchId, newVal);
        }
        this.loadUserTable();
      },
    },
    selectAll: function () {
      if (this.newOpenModalService != 0) {
        if (this.selectedPermissions.length != this.listServices.length) {
          this.selectedPermissions = [];
          this.listServices.forEach((element) => {
            this.selectedPermissions.push(element.id);
          });
        } else {
          this.selectedPermissions = [];
        }
      }
      this.newOpenModalService = 1;
    },
    selectAllGroup: function () {
      if (this.newOpenModalServiceGroup != 0) {
        if (this.selectedPermissionsGroup.length != this.listGroups.length) {
          this.selectedPermissionsGroup = [];
          this.listGroups.forEach((element) => {
            this.selectedPermissionsGroup.push(element.id);
          });
        } else {
          this.selectedPermissions = [];
        }
      }
      this.newOpenModalServiceGroup = 1;
    },
    searchProcess: function () {
      // this.loadProcess();
    },
    pagination: {
      deep: true,
      handler(newVal, oldVal) {
        if (!oldVal) {
          if (!localStorageHelper.get(this.tableId)) {
            localStorageHelper.set(this.tableId, oldVal || userPagination);
          }
        } else {
          localStorageHelper.set(this.tableId, newVal);
        }
      },
    },
  },
  computed: {
    filteredListServices() {
      return this.listServices.filter((service) => {
        return (
          service.title.toLowerCase().includes(this.search.toLowerCase()) || service.titleFormLecom.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    filteredListGroups() {
      return this.listGroups.filter((group) => {
        return group.description.toLowerCase().includes(this.searchGroup.toLowerCase());
      });
    },
    filteredListProcess() {
      if (this.listProcess == null) return [];

      var textSearch = this.searchProcess.toLowerCase();
      return this.listProcess.filter(function (row) {
        return Object.keys(row).some(function (key) {
          return String(row[key]).toLowerCase().indexOf(textSearch) > -1;
        });
      });
    },
    countProcess() {
      if (this.listProcess == null) return 0;
      return this.listProcess.length;
    },
    countPedingProcess() {
      if (this.listProcess == null) return 0;

      return this.listProcess.filter((process) => {
        return process.statusDescription.includes("Pendente");
      }).length;
    },
    countInProcessProcess() {
      if (this.listProcess == null) return 0;

      return this.listProcess.filter((process) => {
        return process.statusDescription.includes("Em andamento");
      }).length;
    },
    countClosedProcess() {
      if (this.listProcess == null) return 0;

      return this.listProcess.filter((process) => {
        return process.statusDescription.includes("Cancelado") || process.statusDescription.includes("Encerrado");
      }).length;
    },
    statisticsData() {
      return [
        {
          title: "Pendente",
          total: this.countPedingProcess,
          icon: "mdi-exclamation",
          color: "warning",
        },
        {
          title: "Andamento",
          total: this.countInProcessProcess,
          icon: "mdi-account-cog",
          color: "success",
        },
        {
          title: "Encerrado",
          total: this.countClosedProcess,
          icon: "mdi-check",
          color: "primary",
        },
        {
          title: "Total",
          total: this.countProcess,
          icon: "mdi-sigma",
          color: "info",
        },
      ];
    },
    tableHeaders: function () {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.user.fields.name"),
          align: "start",
          value: "name",
        },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.email"), value: "email" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.cpf"), value: "cpf" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.phone"), value: "phone" },
        { text: "Nome do " + this.businessNameLabel, value: "businessName" },
        { text: "CNPJ do " + this.businessNameLabel, value: "businessCnpj" },
        { text: this.$vuetify.lang.t("$vuetify.user.fields.status"), value: "status.title" },
        { text: this.$vuetify.lang.t("$vuetify.labels.actions"), value: "actions", width: 200, sortable: false },
      ];
    },
    tableHeadersProcess: function () {
      return [
        { text: this.$vuetify.lang.t("$vuetify.process.fields.processInstanceId"), value: "processInstanceId" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.service"), value: "service" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.nameActivity"), value: "nameActivity" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.createdAt"), value: "createdAt" },
        { text: this.$vuetify.lang.t("$vuetify.process.fields.status"), value: "statusDescription" },
      ];
    },
  },
  async created() {
    this.loadServices();
    this.loadGroups();

    // Get Logged User Data
    this.loggedUser = tokenService.getDecodedToken().user;

    // Check if user exists
    if (!this.loggedUser && !this.loggedUser.id) this.$router.push({ name: "Dashboard" });

    await layoutConfigurationApi.get().then((configurationItems) => {
      configurationItems.forEach((element) => {
        if (element.code === enumContentConfiguration.VUEAPPTITLECLIENT) this.businessNameLabel = element.value;
      });
    });
  },
  filters: {
    // Update Dates from this component to user's timezone
    formatDate: function (value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY");
    },
  },
  methods: {
    loadServices() {
      // Create pagination parameters
      let params = {
        size: 999999,
        sort: "category,asc",
      };
      // Get Users
      serviceApi
        .get(params)
        .then((response) => {
          this.listServices = response.data;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        });
    },
    loadGroups() {
      // Create pagination parameters
      let params = {
        size: 999999,
        sort: "group,asc",
      };
      // Get Groups
      groupApi
        .get(params)
        .then((response) => {
          this.listGroups = response;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.groups.errors.get"),
            text: error.message,
          });
        });
    },
    loadProcess() {
      let userId = this.userProcess.id;
      let processId = this.searchProcess;

      this.listProcess = [];
      // Get Services
      lecomApi
        .getProcess(userId, processId)
        .then((response) => {
          // Get List Itens
          this.listProcess = response;
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.lecom.errors.getPending"),
            text: error.message,
          });
        });
    },
    async loadUserTable() {
      // Reset preview value
      this.loadingTable = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      // Create pagination parameters
      let params = {};
      if (page) params.page = page - 1;
      if (itemsPerPage) params.size = itemsPerPage;
      if (sortBy && sortBy[0]) {
        params.sort = sortBy[0] + "," + (sortDesc[0] ? "desc" : "asc");
      }

      if (this.searchUser != "") {
        params.page = 0;
      }

      params.search = this.searchUser;

      // Get Users
      userApi
        .get(params)
        .then((response) => {
          this.listUsers = response.data;
          this.totalRecords = response.totalRecords;
          this.setPagination();
          this.setSearch();
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    setPagination() {
      if (!localStorageHelper.get(this.tableId)) {
        localStorageHelper.set(this.tableId, userPagination);
      } else {
        this.pagination = Object.assign({}, localStorageHelper.get(this.tableId));
      }
    },
    setSearch() {
      if (!localStorageHelper.get(this.searchId)) {
        localStorageHelper.set(this.searchId, "");
      } else {
        this.searchUser = localStorageHelper.get(this.searchId);
      }
    },
    removeUser(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Users
      userApi
        .delete(userId)
        .then((data) => {
          if (data.deleted) {
            let updateIndex = this.listUsers.findIndex((element) => userId === element.id);
            this.listUsers[updateIndex].status.code = "USU_SUSPENSO";
            this.$notify({
              group: "application",
              type: "success",
              title: this.$vuetify.lang.t("$vuetify.user.notifications.success.removeUser.title"),
              text: this.$vuetify.lang.t("$vuetify.user.notifications.success.removeUser.message"),
            });
          } else {
            this.$notify({
              group: "application",
              type: "error",
              title: this.$vuetify.lang.t("$vuetify.user.notifications.errors.removeUser.title"),
              text: this.$vuetify.lang.t("$vuetify.user.notifications.errors.removeUser.message"),
            });
          }
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.removeConfirmDialog = false;
        });
    },
    approveUser(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Users
      userApi
        .approveUser(userId)
        .then(() => {
          let updateIndex = this.listUsers.findIndex((element) => userId === element.id);
          this.listUsers[updateIndex].status.code = "USU_ATIVO";
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.approveUser.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.approveUser.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.approveConfirmDialog = false;
        });
    },
    unlockUser(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Get Users
      userApi
        .unlockUser(userId)
        .then(() => {
          let updateIndex = this.listUsers.findIndex((element) => userId === element.id);
          this.listUsers[updateIndex].status.code = "USU_ATIVO";
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.unlockUser.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.unlockUser.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.unlockConfirmDialog = false;
        });
    },
    savePermissions(userId) {
      if (!userId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      let arrPermissions = this.listServices.filter((element) => this.selectedPermissions.indexOf(element.id) >= 0);
      // Get Users
      userApi
        .saveUserPermissions(userId, arrPermissions)
        .then(() => {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.user.notifications.success.saveUserPermissions.title"),
            text: this.$vuetify.lang.t("$vuetify.user.notifications.success.saveUserPermissions.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.permissionsDialog = false;
        });
    },
    showPermissionDialog(userObj) {
      if (!userObj.id) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Set User Obj
      this.userPermissions = userObj;
      // Erase Permissions
      this.selectedPermissions = [];
      // Get User Permissions
      userApi
        .getPermissionByUser(userObj.id)
        .then((response) => {
          this.selectedPermissions = response.map((a) => a.id);
          this.newOpenModalService = 0;
          if (this.selectedPermissions.length == this.listServices.length) {
            if (this.selectAll == true) this.newOpenModalService = 1;
            this.selectAll = true;
          } else {
            if (this.selectAll == false) this.newOpenModalService = 1;
            this.selectAll = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.services.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          // Show permissions Dialog
          this.permissionsDialog = true;
        });
    },
    closePermissionDialog() {
      // Hide permissions Dialog
      this.permissionsDialog = false;
      // Set User Obj
      this.userPermissions = {};
      // Erase Permissions
      this.selectedPermissions = [];
    },
    showPermissionDialogGroup(userObj) {
      if (!userObj.id) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Set User Obj
      this.userPermissionsGroup = userObj;
      // Erase Permissions
      this.selectedPermissionsGroup = [];
      // Get User Permissions
      userApi
        .getPermissionByGroup(userObj.id)
        .then((response) => {
          this.selectedPermissionsGroup = response.map((a) => a.id);
          this.newOpenModalGroup = 0;
          if (this.selectedPermissionsGroup.length == this.listGroups.length) {
            if (this.selectAllGroup == true) this.newOpenModalGroup = 1;
            this.selectAllGroup = true;
          } else {
            if (this.selectAllGroup == false) this.newOpenModalGroup = 1;
            this.selectAllGroup = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.user.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          // Show permissions Dialog
          this.permissionsDialogGroup = true;
        });
    },
    savePermissionsGroup(groupId) {
      if (!groupId) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      let arrPermissions = this.listGroups.filter((element) => this.selectedPermissionsGroup.indexOf(element.id) >= 0);
      // Get Users
      groupApi
        .saveGroupPermissions(groupId, arrPermissions)
        .then(() => {
          this.$notify({
            group: "application",
            type: "success",
            title: this.$vuetify.lang.t("$vuetify.groups.notifications.success.saveGroupPermissions.title"),
            text: this.$vuetify.lang.t("$vuetify.groups.notifications.success.saveGroupPermissions.message"),
          });
        })
        .catch((error) => {
          this.$notify({
            group: "application",
            type: "error",
            title: this.$vuetify.lang.t("$vuetify.api.group.errors.get"),
            text: error.message,
          });
        })
        .finally(() => {
          // Hide Overlay
          this.$emit("control-overlay", false);
          this.permissionsDialogGroup = false;
        });
    },
    closePermissionDialogGroup() {
      // Hide permissions Dialog
      this.permissionsDialogGroup = false;
      // Set User Obj
      this.userPermissionsGroup = {};
      // Erase Permissions
      this.selectedPermissionsGroup = [];
    },
    showProcessDialog(userObj) {
      if (!userObj.id) return;
      // Show Overlay
      this.$emit("control-overlay", true);
      // Set User Obj
      this.userProcess = userObj;
      // Get User Process
      this.loadProcess();
      // Show permissions Dialog
      this.processDialog = true;
    },
    closeProcessDialog() {
      // Hide process Dialog
      this.processDialog = false;
      // Set User Obj
      this.userProcess = {};
      this.searchProcess = "";
    },
  },
};
</script>
